const en = {
    serenefireName: 'Serenefire',
    serenefireSlogan: 'Enhance humans. Improve the world.',
    aresName: 'Serenefire Ares',
    aresSlogan: 'Good Luck. Have Fun.',
    starcraftName: 'Ares SC',
    starcraftSlogan: 'The community for starcraft fans.',
};

export const l10n = en;
