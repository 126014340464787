import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import moment from 'moment';
import { getVideo } from '../../actions/VideoAction';
import { getProducts } from '../../actions/ProductAction';
import { l10n } from '../../i18n/Localization';
import ProductCard from '../ProductCard';

class VideoPage extends Component {
  componentDidMount() {
    let videoId = this.props.videoId;
    if (videoId) {
      this.props.getVideo(videoId);
    }

    if (this.props.products.length === 0) {
      this.props.getProducts();
    }
  }

  render() {
    const { video, products } = this.props;

    let tagBadgeSpans = (video && video.tags && video.tags.length) ? (
      video.tags.map(tag => {
        return (
          <span className="badge badge-pill badge-primary m-1" key={tag}>{tag}</span>
        );
      })
    ) : null;

    let randomIndex = Math.floor(Math.random() * products.length);
    let product = (products.length > 0) ? products[randomIndex] : null;
    let productCard = product ? (
      <ProductCard product={product} showWebsiteLink={false} />
    ) : null;

    let content = (
      <div className="container mt-3">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-9 mb-3">
            {
              video ? (
                <div>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" title="video" src={video.embedSrc} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                    </iframe>
                  </div>
                  <div className="mt-3">
                    <h4>{video.title}</h4>
                    <div className="text-secondary">{moment(video.updatedAt.toDate()).calendar()}</div>
                  </div>
                  <div className="h5 mt-3">
                    {tagBadgeSpans}
                  </div>
                </div>
              ) : (
                  <div>loading</div>
                )
            }
          </div>
          <div className="col-12 col-md-6 col-lg-3 mb-3">
            <div className="row">
              <div className="col-12">
                {productCard}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="VideoPage">
        {
          video ? (
            <Helmet>
              <link rel="icon" type="image/x-icon" href="/favicons/favicon_starcraft.ico" />
              <meta name="description" content={video.title} />
              <title>{video.title} - {l10n.starcraftName}</title>
            </Helmet>
          ) : (
              <Helmet>
                <link rel="icon" type="image/x-icon" href="/favicons/favicon_starcraft.ico" />
                <meta name="description" content="Loading video" />
                <title>Loading Video - {l10n.starcraftName}</title>
              </Helmet>
            )
        }
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { videoId } = queryString.parse(ownProps.location.search);

  return {
    videoId: videoId,
    video: state.videoState.video,
    products: state.productState.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVideo: (videoId) => {
      dispatch(getVideo(videoId));
    },
    getProducts: () => {
      dispatch(getProducts());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPage);
