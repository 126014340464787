export const firebaseWebSDKConfig = {
    apiKey: "AIzaSyBNesO-fuWKhJOVBTAh--lMPZm3hkpKfDg",
    authDomain: "ares-7b845.firebaseapp.com",
    databaseURL: "https://ares-7b845.firebaseio.com",
    projectId: "ares-7b845",
    storageBucket: "ares-7b845.appspot.com",
    messagingSenderId: "297687985531",
    appId: "1:297687985531:web:36a8c144815124d6d961de"
};

export const authUrl = "https://auth.serenefire.com/signin.json";
export const adminAppName = "ares-production";
