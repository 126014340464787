import { db, functions } from '../firebase/Firebase';
import { colPath } from '../firebase/FirestoreHelper';

export const VideoActionTypes = {
  GetVideos: 'GetVideos',
  GetVideosDone: 'GetVideosDone',
  GetVideo: 'GetVideo',
  GetVideoDone: 'GetVideoDone',
  WriteVideo: 'WriteVideo',
  WriteVideoDone: 'WriteVideoDone',
};

export const getVideos = (lastVideoId) => {
  return async (dispatch, getState) => {
    try {
      let videoState = getState().videoState;
      let getVideosCompletedInState = videoState.getVideosCompleted;
      if (getVideosCompletedInState === false) {
        return;
      }

      dispatch({
        type: VideoActionTypes.GetVideos,
      });

      let query = db.collection(colPath.videos.starcraft).orderBy('updatedAt', 'desc');

      if (lastVideoId) {
        let docRef = db.doc(`${colPath.videos.starcraft}/${lastVideoId}`);
        let docLastVideo = await docRef.get();

        if (docLastVideo.exists) {
          query = query.startAfter(docLastVideo);
        }
      }

      let querySnapshot = await query.limit(12).get();
      let videos = [];

      for (let i = 0; i < querySnapshot.size; i++) {
        let doc = querySnapshot.docs[i];
        let video = { id: doc.id, ...doc.data() };
        video.watchURL = `/starcraft/watch?videoId=${video.id}`;
        videos.push(video);
      }

      dispatch({
        type: VideoActionTypes.GetVideosDone,
        videos: videos,
      });
    } catch (error) {
      dispatch({
        type: VideoActionTypes.GetVideosDone,
        error: error,
      });
    }
  };
};

export const getVideo = (videoId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: VideoActionTypes.GetVideo,
      });

      let docRef = db.doc(`${colPath.videos.starcraft}/${videoId}`);
      let doc = await docRef.get();
      if (doc.exists) {
        let video = { id: doc.id, ...doc.data() };

        dispatch({
          type: VideoActionTypes.GetVideoDone,
          video: video,
        });
      } else {
        let error = new Error('video does not exist');

        dispatch({
          type: VideoActionTypes.GetVideoDone,
          error: error,
        });
      }
    } catch (error) {
      dispatch({
        type: VideoActionTypes.GetVideoDone,
        error: error,
      });
    }
  };
};

export const writeVideo = (data) => {
  return async (dispatch, getState) => {
    try {
      let videoState = getState().videoState;
      let writeVideoCompletedInState = videoState.writeVideoCompleted;
      if (writeVideoCompletedInState === false) {
        return;
      }

      dispatch({
        type: VideoActionTypes.WriteVideo,
      });

      let writeFunc = functions.httpsCallable('writeVideo');
      let result = await writeFunc(data);

      dispatch({
        type: VideoActionTypes.WriteVideoDone,
        message: result.data.message,
        docId: result.data.docId,
      });
    } catch (error) {
      dispatch({
        type: VideoActionTypes.WriteVideoDone,
        error: error,
      });
    }
  };
};
