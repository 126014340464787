import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { l10n } from '../i18n/Localization';
// import SignedInPanel from './SignedInPanel';
// import SignedOutPanel from './SignedOutPanel';

const Navbar = (props) => {
  // let { authActionCompleted, user } = props.authState;

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <Link className="navbar-brand" to="/">{l10n.aresName}</Link>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item"><NavLink className="nav-link" to="/">Home</NavLink></li>
          <li className="nav-item dropdown">
            <Link className="nav-link dropdown-toggle" to="" id="navbarArticles" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Articles</Link>
            <div className="dropdown-menu" aria-labelledby="navbarArticles">
              <Link className="dropdown-item bg-light" to="/starcraft/articles/">Starcraft</Link>
            </div>
          </li>
        </ul>
        {/* {authActionCompleted ? ( user !== null ? <SignedInPanel /> : <SignedOutPanel />) : null} */}
      </div>
    </nav>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    authState: { ...state.authState },
  };
};

export default connect(mapStateToProps)(Navbar);
