import React from 'react';
import { Link } from 'react-router-dom';

const ProductCard = (props) => {
  const { product, showWebsiteLink } = props;

  return (
    <div className="card text-dark border-light h-100">
      <img className="card-img-top" src={product.logoUrl} alt="logo" />
      <div className="card-body">
        <h5 className="card-title">{product.name}</h5>
        <p className="card-text">{product.description}</p>
        {
          (product.websiteUrl && showWebsiteLink) ? (
            <a href={product.websiteUrl} target="_blank" rel="noopener noreferrer">Visit website!</a>
          ) : null
        }
      </div>
      <div className="card-footer">
        {
          (product.googlePlayUrl) ? (
            <a href={product.googlePlayUrl} target="_blank" rel="noopener noreferrer">
              <img className="w-50" src={product.googlePlayBadgeUrl} alt="Get it on Google Play" />
            </a>) : null
        }
      </div>
    </div>
  );
};

export default ProductCard;
