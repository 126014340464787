import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { auth } from './firebase/Firebase';
import { authStateChanged } from './actions/AuthAction';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import IndexPage from './components/IndexPage';
import NotFoundPage from './components/NotFoundPage';
import StarcraftIndexPage from './components/starcraft/IndexPage';
import StarcraftVideoPage from './components/starcraft/VideoPage';
import StarcraftArticlesPage from './components/starcraft/ArticlesPage';
import StarcraftArticlePage from './components/starcraft/ArticlePage';
import StarcraftMgmtPage from './components/starcraft/MgmtPage';

class App extends Component {
  componentDidMount() {
    this.authUnsubscribe = auth.onAuthStateChanged(user => {
      this.props.authStateChanged(user);
    });
  }

  componentWillUnmount() {
    this.authUnsubscribe();
  }

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/starcraft/" component={StarcraftIndexPage} />
            <Route path="/starcraft/watch" component={StarcraftVideoPage} />
            <Route exact path="/starcraft/articles/" component={StarcraftArticlesPage} />
            <Route path="/starcraft/articles/:articleId" component={StarcraftArticlePage} />
            <Route path="/starcraft/mgmt" component={StarcraftMgmtPage} />
            <Route component={NotFoundPage} />
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    authStateChanged: (user) => {
      dispatch(authStateChanged(user));
    },
  };
};

export default connect(null, mapDispatchToProps)(App);
